export function getList() {
  // return request({
  //   url: '/notice/getList',
  //   method: 'get',
  // })
  return Promise.resolve({
    code: '000000',
    data: {
      total: 500,
      list: [
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
        {
          title: '审核管理',
          message:
            '谢谢谢谢谢寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻',
          isRead: false,
          time: Date.now(),
        },
      ],
    },
  })
}
